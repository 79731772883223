// require('dotenv/config')

const jsonConfig = {
    urlReact: 'https://administrativo.sotertec.com.br',
    urlCliente: 'https://plataforma.sotertec.com.br/plataforma',
    //urlAPI: 'http://localhost:7846/psicologo',
    urlAPI: 'https://psicologo.sotertec.com.br/psicologo',
    APIKEY: `Bearer ${process.env.REACT_APP_APIKEY}`,
    limitDefault: 15
}



export { jsonConfig }